@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --black-gradient: linear-gradient(
    144.39deg,
    #ffffff -278.56%,
    #6d6d6d -78.47%,
    #11101d 91.61%
  );
  --card-shadow: 0px 20px 100px -10px rgba(66, 71, 91, 0.1);
}

* {
  scroll-behavior: smooth;
}

.text-gradient {
  background: radial-gradient(
    64.18% 64.18% at 71.16% 35.69%,
    #ccf9ff 17.23%,
    #7ce8ff 42.04%,
    #55d0ff 55.12%,
    #00acdf 71.54%,
    #0080bf 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.bg-blue-gradient {
  background: linear-gradient(
    157.81deg,
    #ccf9ff -21.24%,
    #7ce8ff 12.19%,
    #55d0ff 29.82%,
    #00acdf 51.94%,
    #0080bf 90.29%
  );
}

.bg-black-gradient {
  background: linear-gradient(
    144.39deg,
    #ffffff -278.56%,
    #6d6d6d -78.47%,
    #11101d 91.61%
  );
}

.bg-black-gradient-2 {
  background: linear-gradient(
    -168.39deg,
    #ffffff -278.56%,
    #6d6d6d -78.47%,
    #11101d 91.61%
  );
}

.bg-gray-gradient {
  background: linear-gradient(
    153.47deg,
    rgba(255, 255, 255, 0) -341.94%,
    #14101d 95.11%
  );
}

.box-shadow {
  box-shadow: 0px 20px 100px -10px rgba(66, 71, 91, 0.1);
}

.sidebar {
  -webkit-animation: slide-top 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.feature-card:hover {
  background: var(--black-gradient);
  box-shadow: var(--card-shadow);
}

.feedback-container .feedback-card:last-child {
  margin-right: 0px;
}

.feedback-card {
  background: transparent;
}

.feedback-card:hover {
  background: var(--black-gradient);
}

.blue__gradient {
  background: linear-gradient(180deg, rgba(188, 165, 255, 0) 0%, #61dafb 100%);
  filter: blur(123px);
}

.blues__gradient {
  background: linear-gradient(90deg, #ccf9ff 0%, #0080bf 100%);
  filter: blur(900px);
}

.white__gradient {
  background: rgba(255, 255, 255, 0.6);
  filter: blur(750px);
}

.social-name {
  width: 0;
}

.social-icon:hover .social-name {
  width: 11rem;
  padding-left: 4rem;
}

.social-icon:hover .social-name:nth-child(1) {
  color: #24292f;
}
.social-icon:hover .social-name:nth-child(n + 1) {
  color: #0080bf;
}
.social-icon:hover .social-name:nth-child(n + 2) {
  color: #00acdf;
}
.social-icon:hover .social-name:nth-child(n + 3) {
  color: #55d0ff;
}
.social-icon:hover .social-name:nth-child(n + 4) {
  color: #7ce8ff;
}

.social-media-icon {
  background-color: white;
}
.social-icon:hover .social-media-icon {
  color: white;
}
.social-icon:hover .social-media-icon:nth-child(1) {
  background-color: #24292f;
}
.social-icon:hover .social-media-icon:nth-child(n + 1) {
  background-color: #0080bf;
}
.social-icon:hover .social-media-icon:nth-child(n + 2) {
  background-color: #00acdf;
}
.social-icon:hover .social-media-icon:nth-child(4) {
  background-color: #55d0ff;
}
.social-icon:hover .social-media-icon:nth-child(5) {
  background-color: #7ce8ff;
}

button {
  background: #0080bf;
}

button:hover {
  background: #00acdf;
}

.link-footer {
  color: #fff;
}

.link-footer:hover {
  color: #00acdf;
}